<template>
  <div id="companyLogin" v-loading="loading">
    <div class="login-btn" @click="login">
    </div>
    <div class="data-list">
      <div v-for="(item ,index) in operateGuide.children" :key="'item4'+index">
        <router-link class="data-item"
                     v-show="item.isIssue=='0'" :to="item.categoryUrl">{{ item.categoryTitle }}
          <i class="el-icon-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsencrypt from "jsencrypt";
import {getUserInfo} from "../../api/commoon/user";
import qs from 'qs'

export default {
  name: "yxLink",
  props: ['company'],
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    let checkCaptcha = (rule, value, callback) => {
      let reg = /^\d{6}$/
      if (!reg.test(value)) {
        callback(new Error('请输入6位数字验证码'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      countdown: 0,
      loginUser: {
        username: "",
        xstr: '',
        captcha: "",
        caKey: "",
        loginType: "0",
        checkCaFlag: "",
        agent: "",
        openid: "",
        unionid: "",
        errorMessage: "",
        loginFlag: 0,
        read: "",
        mobile: null,
        captcha2: null,
      },
      operateGuide: {},
      userLoginFlag: false,
      loginFormRules: {
        // 验证用户名
        username: [
          {required: true, message: '请输入手机/邮箱/用户名', trigger: 'blur'},
        ],
        // 验证密码
        xstr: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
        ],// 验证码
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {type: 'number', validator: checkPhone, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        captcha2: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {type: 'number', validator: checkCaptcha, message: '请输入6位数字验证码', trigger: 'blur'}
        ]
      },
      userInfo: {},
      str: "",
    };
  },
  created() {
    let out = this.getParameter("logout");
    //整个url
    let url = document.location.toString();
    if (out != null && out != undefined && out != "") {
      this.$http.post(this.member + "/member/login/logout").then(res => {
        console.log(res);
      })
      //去除掉logout参数
      document.location = url.replace("logout=1", "");
      //重新加载页面
      window.reload();
    }
    //验证码的是否显示
    this.createImg();
    this.selectCategory("operate_guide", "operateGuide");
    this.checkUser(this.getCookie("uuid"));
  },
  methods: {
    login: function () {
      window.open(this.memberThree + "/ebidding/#/login?redirect=%2Fmine%2Fmine");
    },
    selectCategory: function (modelId, name) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
    getParameter: function (paraName) {
      let url = document.location.toString();
      //去除路由的#号
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      let arrObj = url.split("?");
      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;
        for (let i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getLoginFlag() {
      let that = this;
      axios.post(that.member + "/member/login/getLoginFlag.htm").then((data) => {
        that.loginUser.loginFlag = data.data.loginFlag;
      })
    },
    createImg() {
      let that = this;
      let str = Math.floor(Math.random() * 10000);
      str = that.member + "/member/login/captcha.htm?rnd-" + str;
      this.str = "<img src='" + str + "' style='border-radius: 4px;'>";
    },
    submitForm(formName) {
      let that = this;
      if (!that.loginUser.read) {
        that.$message.error('请阅读并接受用户协议');
      } else {
        this.$refs[formName].validate((valid) => {
          that.loginUser.xstr = that.encrypt(that.loginUser.xstr);
          if (valid) {
            that.loading = true;
            axios.post(that.member + "/member/login/executeVCLogin.htm", that.loginUser).then((data) => {
              that.loginUser.loginFlag = data.data.loginFlag;
              if (data.data.success == '1') {
                that.loading = false;
                location.href = that.cgzxUrl + '/index?expiresTime=' + data.data.expiresTime + '&tokenId=' + data.data.tokenId + '&sessionId=' + data.data.sessionId;
                //登录成功！！
                that.$message.info(data.data.message);
                this.checkUser(this.getCookie("uuid"));
              } else {
                that.loginUser.loginFlag = data.data.loginFlag;
                that.createImg();
                that.$message.error(data.data.message);
              }
            }).finally(res => {
              that.loading = false;
            })
          } else {
            console.log('error submit!!');
          }
        });
      }

    },
    smsLogin(formName) {
      let that = this;
      if (!that.loginUser.read) {
        that.$message.error('请阅读并接受用户协议');
        return;
      }
      const url = that.member + "/member/login/smsLogin.htm";
      const options = {
        method: 'POST',
        data: qs.stringify(that.loginUser),
        url: url
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.loading = true;
          axios(options).then((data) => {
            that.loginUser.loginFlag = data.data.loginFlag;
            if (data.data.success == '1') {
              that.loading = false;
              location.href = that.cgzxUrl + '/index?expiresTime=' + data.data.expiresTime + '&tokenId=' + data.data.tokenId + '&sessionId=' + data.data.sessionId;
              //登录成功！！
              that.$message.info(data.data.message);
              this.checkUser(this.getCookie("uuid"));
            } else {
              that.loginUser.loginFlag = data.data.loginFlag;
              that.createImg();
              that.$message.error(data.data.message);
            }
          }).catch(res => {
            that.loading = false;
          }).finally(res => {
            that.loading = false;
          })
        } else {
        }
      })
    },
    encrypt(str) {
      let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCzrjWyZGR7kNdlnjDYptEB9mEc10NU53muUM/0vbzD8ivekX5zuJ6P7VrjzT7VIu1r9U9B2+xXzSF+2tinEzBpE8z/DAeL235ZmNUQJFIVGvrGUYs4q7nj21n4qNlwfbjpEH2kPkBG3jgAeEHMXj4tkaI5Nb/6Kr+yCZpaSn2U+wIDAQAB";
      let encrypt = new jsencrypt();
      encrypt.setPublicKey(publicKey);
      if (!str) {
        return encrypt.encrypt(null);
      }
      let code = "";
      let lt = str.match(/.{1,117}/g);
      lt.forEach(function (entry) {
        code += encrypt.encrypt(entry);
      });
      if (lt.length > 1) {
        return '^' + code;
      } else {
        return code;
      }
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin == '1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    sendMobileCaptcha: function () {
      //发送短信
      let that = this;
      that.loading = true;
      if (that.loginUser.mobile == null || that.loginUser.mobile == "") {
        that.$message.error("请输入手机号码！");
        that.loading = false;
        return;
      }
      axios.get(that.member + "/member/login/sendMobileCaptcha.html?no_sitemesh", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          mobile: that.loginUser.mobile
        }
      }).then((res) => {
        //提示消息
        let message = res.data.message;
        if (message == null || message == "" || message == undefined) {
          //设置倒计时
          that.countdown = res.data.expireTime;
          that.setTime();
        } else {
          that.$message.error(message);
        }
      }).finally(res => {
        that.loading = false;
      })
    },
    setTime() {
      let that = this;
      if (that.countdown == 0) {
        return false;
      } else {
        that.countdown--;
      }
      //验证码倒计时
      setTimeout(function () {
        that.setTime()
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.login-btn {
  width: 100%;
  height: 70px;
  background: url('../../assets/image/pc/login_btn.png');
  background-size: cover;
  cursor: pointer;
}

.data-item {
  display: flex;
  padding: 12px;
  margin: 0px 15px;
  color: #5B5452;
  font-size: 1.4rem;
  align-items: center;
  border-bottom: 1px solid #F0F1F2;
}
.data-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: white;
}


.data-item i {
  margin-left: auto;
  color: #155BF1;
  font-weight: bold;

}
</style>

<style scoped>
#companyLogin {
  width: 100%;
  height: 100% !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .login-btn {
    width: 100%;
    height: 60px;
    background: url('../../assets/image/pc/login_btn.png');
    background-size: contain;
  }


  .data-item {
    display: flex;
    padding: 8px;
    margin: 0px 10px;
    color: #5B5452;
    align-items: center;
    border-bottom: 1px solid #F0F1F2;
  }
}

@media (min-width: 768px) and (max-width: 992px) {

}

@media (max-width: 576px) {
  .login-btn {
    width: 100%;
    height: 100px;
    background: url('../../assets/image/pc/login_btn.png');
    background-size: 100% 100%;
  }
}

@media (min-width: 1200px) {


}


@media (min-width: 576px ) and (max-width: 768px) {
  #companyLogin {
    height: auto !important;
    background: white !important;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

/*入口*/
</style>
